import { j as a } from "./jsx-runtime-D0AQ1Mn7.js";
import { useState as h, useEffect as w } from "react";
import { t as f, m as g, b as o, i as E, c as i, s as S, f as n } from "./subDays-CmQgBwET.js";
function T(e) {
  return (r) => {
    const s = (e ? Math[e] : Math.trunc)(r);
    return s === 0 ? 0 : s;
  };
}
function k(e, r) {
  return +f(e) - +f(r);
}
function u(e, r, t) {
  const s = k(e, r) / g;
  return T(t == null ? void 0 : t.roundingMethod)(s);
}
function I(e, r, t) {
  const s = o(e, t), y = o(r, t);
  return +s == +y;
}
function m(e, r) {
  return I(e, Date.now(), r);
}
function c(e) {
  return E(e, Date.now());
}
function d(e) {
  return i(e, Date.now());
}
function l(e) {
  return i(e, S(Date.now(), 1));
}
const M = (e, r, t = "s") => `${e} ${r}${e !== 1 ? t : ""}`, $ = (e, r) => {
  const t = u(r, e);
  return t <= 1 ? "Now" : t <= 59 ? ` ${M(t, "min")}` : d(e) ? n(e, "h:mm a") : l(e) ? "Yesterday" : m(e, { weekStartsOn: 1 }) ? n(e, "EEEE") : c(e) ? n(e, "MMM d") : n(e, "MMM d, yyyy");
}, x = (e, r = { noAgo: !1, currentDate: /* @__PURE__ */ new Date() }) => {
  const t = u(r.currentDate, e);
  return t <= 1 ? "Now" : t <= 59 ? `${M(t, "min")}${r.noAgo ? "" : " ago"}` : d(e) ? n(e, "h:mm a") : l(e) ? "Yesterday " + n(e, "h:mm a") : m(e, { weekStartsOn: 1 }) ? n(e, "EEEE h:mm a") : c(e) ? n(e, "MMM d, h:mm a") : n(e, "MMM d, yyyy, h:mm a");
}, W = (e) => {
  const [r, t] = h(/* @__PURE__ */ new Date());
  return w(() => {
    const s = setInterval(() => {
      t(/* @__PURE__ */ new Date());
    }, 1e4);
    return () => clearInterval(s);
  }, []), e.type === "DATE" ? /* @__PURE__ */ a.jsx(a.Fragment, { children: $(new Date(e.dateTime), r) }) : /* @__PURE__ */ a.jsx(a.Fragment, { children: x(new Date(e.dateTime), {
    noAgo: e.noAgo,
    currentDate: r
  }) });
};
export {
  W as D
};
